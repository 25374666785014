import { textileBaseUrl } from "@/config/env";
import request from '@/router/axios';

// 引入使用    import { PurchaseContractIList, PurchaseContractIById,PurchaseContractIAdd, PurchaseContractIEdit, PurchaseContractIDel} from "@/api/Purchase/PurchaseContractApi";
//显示列表查询
export const PurchaseContractIList = (row) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/GetPOContracts",
        method: "get",
        params: {
           ...row
        }
    })
}

//根据ID获取单条信息
export const PurchaseContractIById = (id) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/GetPurchaseContract",
        method: "get",
        params: {
            id
        }
    })
}

//添加
export const PurchaseContractIAdd = (row) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/PostPurchaseContract",
        method: "post",
        data: {
            ...row
        }
    })
}


//更新
export const PurchaseContractIEdit = (row) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/PutPurchaseContract",
        method: "put",
        data: {
            ...row
        }
    })
}

//删除
export const PurchaseContractIDel = (id) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/DeletePurchaseContract",
        method: "delete",
        params: {
            id
        }
    })
}


//获取列表
export const POContractsList = (id) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/GetPOContractsList",
        method: "get",
    })
}